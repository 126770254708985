import styles from "./App.module.sass"
import SearchBar from "./components/serachbar/SearchBar";

import WordLessLogo from "./ressources/wordlesslogo.svg"

function App() {
    return (
        <div>
            <div className={styles.center}>
                <img className={styles.logo} src={WordLessLogo} />
            </div>
            <div className={styles.center}>
                <SearchBar/>
            </div>
        </div>
    );
}


export default App;
