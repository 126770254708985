import styles from "./SearchBar.module.sass"
import * as React from 'react';
import TextField from '@mui/material/TextField';

function SearchBar() {







    return <div className={styles.SearchBar}>
        <TextField
            id="outlined-search"
            label="Search field"
            type="search"
        />
    </div>
}


export default SearchBar